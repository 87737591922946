import { Button } from '@/components/Buttons';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import useRequest from '@/hooks/useRequest';
import { ProductResponse } from '@/types/types';
import React from 'react';
import { useParams } from 'react-router-dom';

const ProductDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useRequest<ProductResponse>({
    url: `/api/products/${id}/`,
    auth: false,
    refresh: true,
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  const { product } = data;
  return (
    <div className="flex flex-col ">
      <Header />
      <div className="flex flex-col items-stretch relative">
        <div className="flex flex-1 flex-col p-10 bg-vanilla rounded-lg shadow-lg justify-between mt-16 md:bg-white mx-auto">
          <img
            src={product?.bucket_key}
            alt={product?.name}
            className="max-w-full mx-auto h-auto object-contain rounded-lg border border-black"
          />

          <div className="flex justify-between items-center my-8">
            <h1 className="text-3xl font-bold text-jet">{product?.name}</h1>
            <p className="text-2xl font-bold text-jet">{product?.price}€</p>
          </div>

          <div className="flex gap-6 my-8 flex-wrap">
            <div className="flex-[2] min-w-fit">
              <h2 className="text-xl font-semibold text-jet">Product Description</h2>
              <p className="text-viridian mb-2">{product?.description}</p>
            </div>

            <div className="flex-1 min-w-fit">
              <h2 className="text-xl font-semibold text-jet">Specifications</h2>
              <ul className="list-disc list-inside text-gray-600">
                <li>Material: 100% Cotton</li>
                <li>Dimensions: 10 x 20 x 15 cm</li>
                <li>Weight: 1.2 kg</li>
                <li>Color: Vibrant Blue</li>
              </ul>
            </div>
          </div>

          <div className="flex gap-14 my-8 flex-wrap">
            <div className="flex-[2] min-w-fit">
              <h2 className="text-xl font-semibold text-jet">Customer Reviews</h2>
              <p className="text-gray-600">
                "Amazing product! Exceeded my expectations." – Jane D.
              </p>
              <p className="text-gray-600">
                "Would definitely buy again. Great value for the price." – Mark T.
              </p>
            </div>

            <div className="flex-1 min-w-fit">
              <h2 className="text-xl font-semibold text-jet">Seller Information</h2>
              <p className="text-gray-600">
                Sold by: <span className="font-medium">John's Store</span>
              </p>
              <p className="text-gray-600">Location: San Francisco, CA, USA</p>
              <p className="text-gray-600">Member since: 2021</p>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <Button className="self-end">Contact Seller</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
